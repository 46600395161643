/* eslint-disable */
export const templateContactData = {
    init() {
        // JavaScript to be fired on all pages

        function new_map( $el ) {

            // var
            var $markers = $el.find('.marker');

            // vars
            var args = {
                zoom        : 5,
                center      : new google.maps.LatLng(0, 0),
                mapTypeId   : google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true,
                zoomControl: true
            };

            // create map
            var map = new google.maps.Map( $el[0], args);

            // add a markers reference
            map.markers = [];

            // add markers
            $markers.each(function(){
                add_marker( $(this), map );
            });

            // center map
            center_map( map );

            // return
            return map;
        }

        /*
        *  add_marker
        *
        *  This function will add a marker to the selected Google Map
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	4.3.0
        *
        *  @param	$marker (jQuery element)
        *  @param	map (Google Map object)
        *  @return	n/a
        */

        //declare infowindow in global space
        var infowindow = new google.maps.InfoWindow();

        function add_marker( $marker, map ) {
            // var
            var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

            var image = {
                url: '/app/themes/sage/resources/assets/svg/map-marker-alt-solid.svg',
                size: new google.maps.Size(26, 38),
                scaledSize: new google.maps.Size(26, 38)
            };

            // create marker
            var marker = new google.maps.Marker({
                position	: latlng,
                map			: map,
                icon        : image
            });

            // add to array
            map.markers.push( marker );

            // if marker contains HTML, add it to an infoWindow
            if( $marker.html() )
            {
                // show info window when marker is clicked
                google.maps.event.addListener(marker, 'click', function() {
                    //add contentString using this syntax
                    infowindow.setContent($marker.html());
                    infowindow.open( map, marker );
                });
            }
        }

        /*
        *  center_map
        *
        *  This function will center the map, showing all markers attached to this map
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	4.3.0
        *
        *  @param	map (Google Map object)
        *  @return	n/a
        */

        function center_map( map ) {
            // vars
            var bounds = new google.maps.LatLngBounds();
                // loop through all markers and create bounds
                $.each( map.markers, function( i, marker ){
                var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
                bounds.extend( latlng );
            });

            // only 1 marker?
            if( map.markers.length == 1 )
            {
                // set center of map
                map.setCenter( bounds.getCenter() );
                map.setZoom( 11 );
            }
            else
            {
                // fit to bounds
                map.fitBounds( bounds );
            }

        }

        /*
        *  document ready
        *
        *  This function will render each map when the document is ready (page has loaded)
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	5.0.0
        *
        *  @param	n/a
        *  @return	n/a
        */
        // global var
        var map = null;
        $(document).ready(function(){
            $('.acf-map').each(function(){
                // create map
                map = new_map( $(this));
            });
        });


    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
